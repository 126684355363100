import LazyLoad from 'vanilla-lazyload'

AOS.init({
    duration: 600,
    mirror: false,
    once: true
})

window.lazyLoad = new LazyLoad({
    elements_selector: '[data-src], [data-srcset], [data-bg]'
})

document.querySelector('.js-burger').addEventListener('click', function (e) {
    this.classList.toggle('is-active')
    document.querySelector('.js-navigation').classList.toggle('is-active')
    document.documentElement.classList.toggle('is-scroll-lock')
})

document.querySelectorAll('.navigation__link').forEach(el => {
    if (el.parentElement.classList.contains('has-submenu')) {
        el.addEventListener('click', function (e) {
            e.preventDefault()
            el.parentElement.classList.toggle('is-active')
        })
    }
})


document.querySelectorAll('.contacts__map').forEach(el => {
    var lat = parseFloat(el.dataset.lat)
    var lon = parseFloat(el.dataset.lon)
    var latlng = { lat: lat, lng: lon }
    var options = {
        zoom: 16,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        panControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP
        },
        styles: [
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29446b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#193a55"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "saturation": 25
                    },
                    {
                        "lightness": 25
                    },
                    {
                        "weight": "0.01"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": -80
                    }
                ]
            }
        ]
    }
    var map = new google.maps.Map(el, options)
    var marker = new google.maps.Marker({
        position: latlng,
        map: map,
    })
})

