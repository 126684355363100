import Vue from 'vue'

var dev = true
Vue.config.silent = !dev
Vue.config.devtools = dev

Vue.mixin({
    methods: {
        doPost(url, argsObj, callback) {
            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(argsObj)
            }).then((response) => {
                response.json().then((data) => {
                    callback(data)
                })
            })
        },
    },
    updated() {
        window.lazyLoad.update()
    }
})


export default Vue