import Vue from './config'

if (document.getElementById('vue-news')) {
    new Vue({
        el: '#vue-news',
        data: {
            items: [],
            page: 0,
            perPage: 12
        },
        watch: {
        },
        computed: {
            displayPage() {
                return this.page + 1
            },
            pages() {
                return Math.ceil(this.items.length / this.perPage)
            },
            curPage() {
                return this.items.slice(this.page * this.perPage, (this.page * this.perPage) + this.perPage)
            },
        },
        methods: {
            doSearch() {
                let vm = this
                vm.doPost('/umbraco/api/data/news', null, function (data) {
                    vm.items = data
                })
            },
            setPage(p) {
                this.page = p
                document.querySelector('.members__cards').scrollIntoView({ behavior: 'smooth' })
            }
        },
        mounted() {
            this.doSearch()
            AOS.init({ duration: 600 })
        }
    })
}