import Vue from './config'

if (document.getElementById('vue-team')) {
    new Vue({
        el: '#vue-team',
        data: {
            services: [],
            filters: [],
            people: [],
            serviceId: 0,
            filterId: 0,
            query: '',
            page: 0,
            perPage: 12
        },
        watch: {
            serviceId() {
                this.page = 0
                this.doSearch()
            },
            filterId() {
                this.page = 0
                this.doSearch()
            },
            query() {
                this.page = 0
                this.doSearch()
            },
        },
        computed: {
            displayPage() {
                return this.page + 1
            },
            pages() {
                return Math.ceil(this.people.length / this.perPage)
            },
            curPage() {
                return this.people.slice(this.page * this.perPage, (this.page * this.perPage) + this.perPage)
            },
        },
        methods: {
            doSearch() {
                let vm = this
                window.localStorage.setItem('serviceId', vm.serviceId)
                window.localStorage.setItem('filterId', vm.filterId)
                vm.doPost('/umbraco/api/data/searchPeople', { query: this.query, serviceId: this.serviceId, filterId: this.filterId }, function (data) {
                    vm.people = data
                })
            },
            getServices() {
                let vm = this
                vm.doPost('/umbraco/api/data/getPeopleServices', null, function (data) {
                    vm.services = data
                })
            },
            getFilters() {
                let vm = this
                vm.doPost('/umbraco/api/data/getPeopleFilters', null, function (data) {
                    vm.filters = data
                    if (vm.filterId == 0) vm.filterId = vm.filters[0].id
                })
            },
            setPage(p) {
                this.page = p
                document.querySelector('.members__cards').scrollIntoView({ behavior: 'smooth' })
            }
        },
        mounted() {
            AOS.init({ duration: 600 })
            var sid = window.localStorage.getItem('serviceId')
            if (sid != null && sid != '') this.serviceId = parseInt(sid)
            var fid = window.localStorage.getItem('filterId')
            if (fid != null && fid != '') this.filterId = parseInt(fid)
            this.getServices()
            this.getFilters()
            this.doSearch()
        }
    })
}